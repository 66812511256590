<template>
  <v-container class="fill-height">
    <v-row justify="center" align="center" v-if="loading">
      <v-col cols="12" class="grey--text text-center">
        <v-progress-circular indeterminate size="32" />
        <div class="caption my-2">Loading charts...</div>
      </v-col>
    </v-row>

    <v-row justify="center" align="center" v-else-if="!chartItems.length">
      <v-col cols="12" class="grey--text text-center">
        <v-icon class="ma-2" size="48">mdi-alert</v-icon>
        <div>You do not have access to any charts</div>
      </v-col>
    </v-row>

    <template v-else>
      <template v-for="(rows, i) in chartItems">
        <v-row v-if="i" :key="i * 10">
          <v-col cols="12">
            <v-divider />
          </v-col>
        </v-row>
        <v-row justify="center" :key="i * 10 + 1">
          <v-col cols="12" lg="6" v-for="(item, j) in rows" :key="item.key * 1e5 + i * 1e2 + j">
            <v-card :loading="item.key === -1">
              <v-card elevation="0" tile class="d-flex grey lighten-5" :to="openRoute(item.route)">
                <span class="py-2 px-5 title">{{ item.title }}</span>
                <v-spacer />
                <v-icon class="px-5">{{ item.icon }}</v-icon>
              </v-card>
              <v-divider />
              <v-card-text class="py-6" v-if="item.chartType === 'LineChart'">
                <LineChart :show-data="item.data" :height="300" />
              </v-card-text>
              <v-card-text class="py-6" v-else-if="item.chartType === 'Doughnut'">
                <Doughnut :show-data="item.data" :height="300" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </template>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import * as api from "@/constants/api";
import { chunk } from "lodash";

export default {
  name: "Dashboard",
  metaInfo: { title: "Dashboard" },
  components: {
    Doughnut: () => import("@/views/components/Dashboard/Charts/Doughnut"),
    LineChart: () => import("@/views/components/Dashboard/Charts/LineChart"),
  },
  data: () => ({
    refreshTimer: null,
  }),
  computed: {
    ...mapGetters({
      registrationDataKey: "dashboard/getRegistrationDataKey",
      registrationData: "dashboard/getRegistrationData",
      userDataKey: "dashboard/getUserDataKey",
      userData: "dashboard/getUserData",
      faceLivenessDataKey: "dashboard/getFaceLivenessDataKey",
      faceLivenessData: "dashboard/getFaceLivenessData",
      faceMatchDataKey: "dashboard/getFaceMatchDataKey",
      faceMatchData: "dashboard/getFaceMatchData",
      ocrDataKey: "dashboard/getOcrDataKey",
      ocrData: "dashboard/getOcrData",
      ocrDataDailyKey: "dashboard/getOcrDataDailyKey",
      ocrDataDaily: "dashboard/getOcrDataDaily",
      ocrDataMonthlyKey: "dashboard/getOcrDataMonthlyKey",
      ocrDataMonthly: "dashboard/getOcrDataMonthly",
      parseNidDataKey: "dashboard/getParseNidDataKey",
      parseNidData: "dashboard/getParseNidData",
      porichoyRequestDataKey: "dashboard/getPorichoyRequestDataKey",
      porichoyRequestData: "dashboard/getPorichoyRequestData",
      porichoyRequestDataDailyKey: "dashboard/getPorichoyRequestDataDailyKey",
      porichoyRequestDataDaily: "dashboard/getPorichoyRequestDataDaily",
      porichoyRequestDataMonthlyKey: "dashboard/getPorichoyRequestDataMonthlyKey",
      porichoyRequestDataMonthly: "dashboard/getPorichoyRequestDataMonthly",
      payableRequestDataKey: "dashboard/getPayableRequestDataKey",
      payableRequestData: "dashboard/getPayableRequestData",
      scraperRequestDataKey: "dashboard/getScraperRequestDataKey",
      scraperRequestData: "dashboard/getScraperRequestData",
    }),
    loading() {
      for (const items of this.chartItems) {
        for (const item of items) {
          if (item.key === -1) {
            return true;
          }
        }
      }
      return false;
    },
    currentMonth() {
      return moment().format("MMMM");
    },
    chartItems() {
      let firstRow = [];
      if (window.CBS_ONLY === "true") {
        firstRow.push({
          chartType: "Doughnut",
          title: "CBS Onboarding Requests",
          icon: "mdi-chevron-right",
          key: this.registrationDataKey,
          data: this.registrationData,
          route: {
            name: "cbs.index",
            requirePrivilege: { path: api.ONBOARDING_CBS_REGISTRATIONS },
          },
          requirePrivilege: { path: api.ONBOARDING_CBS_COUNT_REGISTRATIONS },
          dispatcherName: "dashboard/fetchRegistrationCounts",
          dispatcherParams: { countApi: api.ONBOARDING_CBS_COUNT_REGISTRATIONS },
        });
      } else {
        firstRow.push(
          {
            chartType: "Doughnut",
            title: "Registered Users",
            icon: "mdi-chevron-right",
            key: this.userDataKey,
            data: this.userData,
            route: {
              name: "user.index",
              requirePrivilege: { path: api.USER_LIST },
            },
            requirePrivilege: { path: api.USER_TOTAL_BY_ROLES },
            dispatcherName: "dashboard/fetchUserCounts",
            dispatcherParams: { countApi: api.ONBOARDING_MFS_COUNT_REGISTRATIONS },
          },
          {
            chartType: "Doughnut",
            title: "MFS Onboarding Requests",
            icon: "mdi-chevron-right",
            key: this.registrationDataKey,
            data: this.registrationData,
            route: {
              name: "mfs.index",
              requirePrivilege: { path: api.ONBOARDING_MFS_REGISTRATIONS },
            },
            requirePrivilege: { path: api.ONBOARDING_MFS_COUNT_REGISTRATIONS },
            dispatcherName: "dashboard/fetchRegistrationCounts",
          }
        );
      }
      return chunk(
        [
          ...firstRow,
          {
            chartType: "LineChart",
            title: `Face Liveness Requests (${this.faceLivenessDataKey} total)`,
            icon: "mdi-chevron-right",
            key: this.faceLivenessDataKey,
            data: this.faceLivenessData,
            route: {
              name: "liveness-request.index",
              requirePrivilege: { path: api.FACE_LIVENESS_REQUESTS },
            },
            requirePrivilege: { path: api.FACE_LIVENESS_REQUESTS_OVER_TIME },
            dispatcherName: "dashboard/fetchLivenessRequestCounts",
          },
          {
            chartType: "LineChart",
            title: `Face Match Requests (${this.faceMatchDataKey} total)`,
            icon: "mdi-chevron-right",
            key: this.faceMatchDataKey,
            data: this.faceMatchData,
            route: {
              name: "match-request.index",
              requirePrivilege: { path: api.FACE_MATCH_REQUESTS },
            },
            requirePrivilege: { path: api.FACE_MATCH_REQUESTS_OVER_TIME },
            dispatcherName: "dashboard/fetchMatchRequestCounts",
          },

          {
            chartType: "LineChart",
            title: `NID Parse Requests (${this.parseNidDataKey} total)`,
            icon: "mdi-chevron-right",
            key: this.parseNidDataKey,
            data: this.parseNidData,
            route: {
              name: "parse-request.index",
              requirePrivilege: { path: api.PARSE_REQUESTS },
            },
            requirePrivilege: { path: api.PARSE_REQUESTS_OVER_TIME },
            dispatcherName: "dashboard/fetchParseNidRequestCounts",
          },
          {
            chartType: "LineChart",
            title: `NID OCR Requests (${this.ocrDataKey} total)`,
            icon: "mdi-chevron-right",
            key: this.ocrDataKey,
            data: this.ocrData,
            route: {
              name: "ocr-request.index",
              requirePrivilege: { path: api.OCR_REQUESTS },
            },
            requirePrivilege: { path: api.OCR_REQUESTS_OVER_TIME },
            dispatcherName: "dashboard/fetchOCRRequestCounts",
          },
          {
            chartType: "LineChart",
            title: `NID OCR Requests (${this.ocrDataDailyKey} today)`,
            icon: "mdi-chevron-right",
            key: this.ocrDataDailyKey,
            data: this.ocrDataDaily,
            route: {
              name: "ocr-request.index",
              requirePrivilege: { path: api.OCR_REQUESTS },
            },
            requirePrivilege: { path: api.OCR_REQUESTS_OVER_TIME },
            dispatcherName: "dashboard/fetchDailyOCRRequestCounts",
          },
          {
            chartType: "LineChart",
            title: `NID OCR Requests (${this.ocrDataMonthlyKey} this month) [${this.currentMonth}]`,
            icon: "mdi-chevron-right",
            key: this.ocrDataMonthlyKey,
            data: this.ocrDataMonthly,
            route: {
              name: "ocr-request.index",
              requirePrivilege: { path: api.OCR_REQUESTS },
            },
            requirePrivilege: { path: api.OCR_REQUESTS_OVER_TIME },
            dispatcherName: "dashboard/fetchMonthlyOCRRequestCounts",
          },
          {
            chartType: "LineChart",
            title: `NID Scraper Requests (${this.scraperRequestDataKey} total)`,
            icon: "mdi-chevron-right",
            key: this.scraperRequestDataKey,
            data: this.scraperRequestData,
            route: {
              name: "nid-scraper.index",
              requirePrivilege: { path: api.NID_SCRAPER_REQUESTS },
            },
            requirePrivilege: { path: api.NID_SCRAPER_REQUESTS_OVER_TIME },
            dispatcherName: "dashboard/fetchScraperRequestCounts",
          },
          {
            chartType: "LineChart",
            title: `Porichoy Requests (${this.porichoyRequestDataKey} total)`,
            icon: "mdi-chevron-right",
            key: this.porichoyRequestDataKey,
            data: this.porichoyRequestData,
            route: {
              name: "porichoy-request.index",
              requirePrivilege: { path: api.PORICHOY_REQUESTS },
            },
            requirePrivilege: { path: api.PORICHOY_REQUESTS_OVER_TIME },
            dispatcherName: "dashboard/fetchPorichoyRequestCounts",
          },
          {
            chartType: "LineChart",
            title: `Porichoy Requests (${this.porichoyRequestDataDailyKey} today)`,
            icon: "mdi-chevron-right",
            key: this.porichoyRequestDataDailyKey,
            data: this.porichoyRequestDataDaily,
            route: {
              name: "porichoy-request.index",
              requirePrivilege: { path: api.PORICHOY_REQUESTS },
            },
            requirePrivilege: { path: api.PORICHOY_REQUESTS_OVER_TIME },
            dispatcherName: "dashboard/fetchDailyPorichoyRequestCounts",
          },
          {
            chartType: "LineChart",
            title: `Porichoy Requests (${this.porichoyRequestDataMonthlyKey} this month) [${this.currentMonth}]`,
            icon: "mdi-chevron-right",
            key: this.porichoyRequestDataMonthlyKey,
            data: this.porichoyRequestDataMonthly,
            route: {
              name: "porichoy-request.index",
              requirePrivilege: { path: api.PORICHOY_REQUESTS },
            },
            requirePrivilege: { path: api.PORICHOY_REQUESTS_OVER_TIME },
            dispatcherName: "dashboard/fetchMonthlyPorichoyRequestCounts",
          },
          // {
          //   chartType: "LineChart",
          //   title: `Payable Porichoy Requests (${this.payableRequestDataKey})`,
          //   icon: "mdi-chevron-right",
          //   key: this.payableRequestDataKey,
          //   data: this.payableRequestData,
          //   route: {
          //     name: "porichoy-request.index",
          //     requirePrivilege: { path: api.PORICHOY_REQUESTS },
          //   },
          //   requirePrivilege: { path: api.PORICHOY_PAYABLE_OVER_TIME },
          //   dispatcherName: "dashboard/fetchPayableRequestCounts",
          // },
        ],
        2
      )
        .map((items) =>
          items.filter((item) => {
            if (!item.requirePrivilege) return true;
            return this.verifyPrivilege(item.requirePrivilege.path, item.requirePrivilege.method);
          })
        )
        .filter((items) => items && items.length > 0);
    },
  },
  mounted() {
    this.refreshDashboard();
    this.refreshTimer = setInterval(() => this.refreshDashboard(), 120000);
  },
  destroyed() {
    clearInterval(this.refreshTimer);
  },
  methods: {
    async refreshDashboard() {
      try {
        const promises = [];
        for (const items of this.chartItems) {
          for (const item of items) {
            promises.push(this.$store.dispatch(item.dispatcherName, item.dispatcherParams));
          }
        }
        await Promise.all(promises);
      } catch (err) {
        this.$iziToast.showError(err);
      }
    },
    openRoute(conf) {
      if (conf.requirePrivilege) {
        if (!this.verifyPrivilege(conf.requirePrivilege.path, conf.requirePrivilege.method)) {
          return null;
        }
      }
      return { name: conf.name };
    },
  },
};
</script>
